import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import 'core-js'
import React from 'react'
// import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './modules/utilities/reportWebVitals'
import { Provider } from 'react-redux'
import store from './modules/repositories/store'

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root'),
// )
const root = createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
reportWebVitals()
console.log('App 1.3.2')
